import React from 'react';
import PropTypes from 'prop-types';
import DateInput from '../DateInput/DateInput';
import HandleNumericOnInputWithForcedMinMax from '../../utils/HandleNumericInputWithEnhancedMinMax';

class RuleField extends React.Component {
  // Handler for checkbox input (boolean)
  handleCheckboxChange = e => {
    const { relativeIndex, handleUserDefinedValueChange } = this.props;
    handleUserDefinedValueChange(relativeIndex, e.target.checked);
  };

  // Handler for text and number inputs
  handleInputChange = e => {
    const { relativeIndex, handleUserDefinedValueChange } = this.props;
    handleUserDefinedValueChange(relativeIndex, e.target.value);
  };

  // Handler for date input
  handleDateChange = date => {
    const { relativeIndex, handleUserDefinedValueChange } = this.props;
    handleUserDefinedValueChange(relativeIndex, date?.toString());
  };

  // Handler for rule definition change
  handleRuleDefinitionChange = e => {
    const { relativeIndex, handleRuleChange } = this.props;
    handleRuleChange(relativeIndex, 'rule_definition_id', e.target.value);
  };

  // Handler for remove rule button
  handleRemoveRule = () => {
    const { relativeIndex, removeRule } = this.props;
    removeRule(relativeIndex);
  };

  getInputForRule = () => {
    const { rule, index, ruleDefinitions, disableEdit } = this.props;

    const ruleDefinition = ruleDefinitions.find(
      def => def.id === rule.rule_definition_id
    );
    const allowedInputType = ruleDefinition?.allowed_input_type;
    const inputId = `user-defined-value-${index}`;

    switch (allowedInputType) {
      case 'boolean':
        return (
          <>
            <input
              type="hidden"
              value={false}
              name={`promotion[rules_attributes][${index}][user_defined_value]`}
            />
            <input
              type="checkbox"
              id={inputId}
              name={`promotion[rules_attributes][${index}][user_defined_value]`}
              className="form-control user-defined-value m-0"
              checked={Boolean(rule.user_defined_value)}
              value={rule.user_defined_value ? true : false}
              disabled={disableEdit}
              onChange={this.handleCheckboxChange}
            />
          </>
        );
      case 'trueonly':
        return (
          <input
            type="hidden"
            value="true"
            name={`promotion[rules_attributes][${index}][user_defined_value]`}
          />
        );
      case 'falseonly':
        return (
          <input
            type="hidden"
            value="false"
            name={`promotion[rules_attributes][${index}][user_defined_value]`}
          />
        );
      case 'integer':
        return (
          <input
            type="number"
            min={0}
            max={10000}
            onInput={HandleNumericOnInputWithForcedMinMax}
            autoComplete="off"
            id={inputId}
            disabled={disableEdit}
            name={`promotion[rules_attributes][${index}][user_defined_value]`}
            className="form-control user-defined-value"
            value={rule.user_defined_value}
            onChange={this.handleInputChange}
          />
        );
      case 'datetime':
        return (
          <DateInput
            id={inputId}
            autoComplete="off"
            name={`promotion[rules_attributes][${index}][user_defined_value]`}
            className="form-control user-defined-value"
            dateFormat={'MM/dd/yyyy'}
            value={rule.user_defined_value || ''}
            label=""
            invisibleLabel
            required
            disabled={disableEdit}
            onChange={this.handleDateChange}
          />
        );
      case 'string':
        return (
          <input
            type="text"
            autoComplete="off"
            name={`promotion[rules_attributes][${index}][user_defined_value]`}
            id={inputId}
            disabled={disableEdit}
            className="form-control user-defined-value"
            value={rule.user_defined_value || ''}
            onChange={this.handleInputChange}
          />
        );
      default:
        return '';
    }
  };

  render() {
    const { rule, index, ruleDefinitions, errors, disableEdit } = this.props;

    return (
      <div className="rule-fields my-8 flex flex-wrap">
        <input
          type="hidden"
          name={`promotion[rules_attributes][${index}][_destroy]`}
          value={rule._destroy}
        />
        <input
          type="hidden"
          name={`promotion[rules_attributes][${index}][id]`}
          value={rule.id || ''}
        />

        {!rule._destroy && (
          <>
            <div className="inline-block field basis-6/12 mr-3">
              <select
                name={`promotion[rules_attributes][${index}][rule_definition_id]`}
                value={rule.rule_definition_id || ''}
                className="form-control select required"
                disabled={disableEdit}
                onChange={this.handleRuleDefinitionChange}
              >
                <option value="">Select a rule...</option>
                {ruleDefinitions.map(definition => (
                  <option
                    key={definition.id}
                    value={definition.id}
                    data-allowed-input-type={definition.allowed_input_type}
                  >
                    {definition.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="inline-block field basis-3/12 mr-3 flex justify-center">
              {this.getInputForRule()}
            </div>

            {disableEdit === false && (
              <button
                type="button"
                className="inline-block remove-rule-button basis-2/12 text-navy-lighter"
                onClick={this.handleRemoveRule}
              >
                Remove
              </button>
            )}

            {errors[`user_defined_value_${this.props.relativeIndex}`] && (
              <span className="error-message w-full py-6 text-danger">
                {errors[`user_defined_value_${this.props.relativeIndex}`]}
              </span>
            )}

            {errors[`rule_definition_id_${this.props.relativeIndex}`] && (
              <span className="error-message w-full py-6 text-danger">
                {errors[`rule_definition_id_${this.props.relativeIndex}`]}
              </span>
            )}

            {errors[
              `rule_definition_field_lock_${this.props.relativeIndex}`
            ] && (
              <span className="error-message w-full py-6 text-danger">
                {
                  errors[
                    `rule_definition_field_lock_${this.props.relativeIndex}`
                  ]
                }
              </span>
            )}
          </>
        )}
      </div>
    );
  }
}

RuleField.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.string,
    rule_definition_id: PropTypes.string,
    user_defined_value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number
    ]),
    _destroy: PropTypes.bool
  }).isRequired,
  index: PropTypes.number.isRequired,
  relativeIndex: PropTypes.number.isRequired,
  ruleDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      allowed_input_type: PropTypes.oneOf([
        'string',
        'integer',
        'boolean',
        'datetime',
        'trueonly',
        'falseonly'
      ]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  handleRuleChange: PropTypes.func.isRequired,
  handleUserDefinedValueChange: PropTypes.func.isRequired,
  removeRule: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  disableEdit: PropTypes.bool.isRequired
};

export default RuleField;

import React from 'react';
import PropTypes from 'prop-types';
import RuleField from './RuleField';

class RuleFieldsList extends React.Component {
  constructor(props) {
    super(props);

    this.uniqueKeyCounter = 0;

    this.state = {
      rules: props.initialRules.map(rule => ({
        ...rule,
        key: rule.id || this.generateUniqueKey()
      })),
      errors: {}
    };
  }

  componentDidMount() {
    this.handleValidation(true);
  }

  generateUniqueKey = () => {
    return `new-${this.uniqueKeyCounter++}`;
  };

  findRuleDefinition = id => {
    return this.props.ruleDefinitions.find(def => def.id === id);
  };

  validateRules = () => {
    const { rules } = this.state;
    const errors = {};

    const keysForDuplicateRuleDefinitionIds = rules
      .filter(rule => !rule._destroy)
      .map(currentRule => {
        const hasDuplicate = rules.some(
          otherRule =>
            otherRule.rule_definition_id === currentRule.rule_definition_id &&
            otherRule.key !== currentRule.key &&
            !otherRule._destroy
        );
        return hasDuplicate ? currentRule.key : null;
      })
      .filter(ruleKey => ruleKey !== null);

    const allFieldLocks = rules
      .filter(rule => !rule._destroy)
      .map(currentRule => {
        return this.findRuleDefinition(currentRule.rule_definition_id)
          ?.field_lock;
      });

    const duplicatedFieldLocks = allFieldLocks.filter((fieldLock, index) => {
      return allFieldLocks.indexOf(fieldLock) !== index;
    });

    // Check for type mismatches in user_defined_value
    rules.forEach((rule, index) => {
      if (!rule._destroy && rule.rule_definition_id) {
        const ruleDefinition = this.findRuleDefinition(rule.rule_definition_id);

        if (ruleDefinition) {
          if (keysForDuplicateRuleDefinitionIds.indexOf(rule.key) !== -1) {
            errors[`rule_definition_id_${index}`] =
              'Duplicate rule definitions are not allowed.';
          }

          if (duplicatedFieldLocks.indexOf(ruleDefinition.field_lock) !== -1) {
            errors[`rule_definition_field_lock_${index}`] =
              'There are at least two rules trying to set a filter by the same field.';
          }

          const { allowed_input_type } = ruleDefinition;
          const value = rule.user_defined_value;

          switch (allowed_input_type) {
            case 'boolean':
              if (value !== true && value !== false) {
                errors[`user_defined_value_${index}`] =
                  'Value must be a boolean (true/false).';
              }
              break;
            case 'integer':
              if (
                isNaN(value) ||
                value === '' ||
                !Number.isInteger(Number(value))
              ) {
                errors[`user_defined_value_${index}`] =
                  'Value must be a number.';
              }
              break;
            case 'datetime':
              if (value === '' || isNaN(Date.parse(value))) {
                errors[`user_defined_value_${index}`] =
                  'Value must be a valid date.';
              }
              break;
            case 'string':
              if (value === '' || typeof value !== 'string') {
                errors[`user_defined_value_${index}`] =
                  'Value must be a string.';
              }
              break;
            default:
              break;
          }
        }
      }
    });

    return errors;
  };

  addRule = () => {
    const newRule = {
      id: null,
      rule_definition_id: '',
      user_defined_value: '',
      _destroy: false,
      key: this.generateUniqueKey() // Assign unique key
    };
    this.setState(prevState => ({
      rules: [...prevState.rules, newRule]
    }));
  };

  removeRule = relativeIndex => {
    this.setState(prevState => {
      const updatedRules = [...prevState.rules];
      if (updatedRules[relativeIndex].id) {
        updatedRules[relativeIndex]._destroy = true; // Mark for destruction if it exists
      } else {
        updatedRules.splice(relativeIndex, 1); // Remove new rule
      }
      return { rules: updatedRules };
    }, this.handleValidation);
  };

  handleRuleChange = (relativeIndex, field, value) => {
    this.setState(prevState => {
      const updatedRules = [...prevState.rules];
      updatedRules[relativeIndex] = {
        ...updatedRules[relativeIndex],
        [field]: value
      };
      // Ensure the key is preserved
      return { rules: updatedRules };
    }, this.handleValidation);
  };

  handleUserDefinedValueChange = (relativeIndex, value) => {
    this.setState(prevState => {
      const updatedRules = [...prevState.rules];
      updatedRules[relativeIndex] = {
        ...updatedRules[relativeIndex],
        user_defined_value: value
      };
      // Ensure the key is preserved
      return { rules: updatedRules };
    }, this.handleValidation);
  };

  handleValidation = () => {
    const errors = this.validateRules();
    this.setState({ errors });
  };

  getAvailableRuleDefinitions = currentRuleKey => {
    const { rules } = this.state;
    const { ruleDefinitions } = this.props;

    const selectedRuleDefinitionIds = rules
      .filter(rule => !rule._destroy && rule.key !== currentRuleKey)
      .map(rule => rule.rule_definition_id);

    return ruleDefinitions.filter(
      ruleDefinition => !selectedRuleDefinitionIds.includes(ruleDefinition.id)
    );
  };

  render() {
    const { rules, errors } = this.state;
    const { title, startingIndex } = this.props;

    return (
      <div id="rules-fields">
        <h3 className="text-bold text-xs uppercase text-gray-500 mb-6">
          {title}
        </h3>
        {rules.map((rule, relativeIndex) => (
          <RuleField
            key={rule.key} // Use the unique key
            rule={rule}
            index={startingIndex + relativeIndex}
            relativeIndex={relativeIndex}
            ruleDefinitions={this.getAvailableRuleDefinitions(rule.key)}
            handleRuleChange={this.handleRuleChange}
            handleUserDefinedValueChange={this.handleUserDefinedValueChange}
            removeRule={this.removeRule}
            disableEdit={this.props.disableEdit}
            errors={errors}
          />
        ))}

        {this.props.disableEdit === false && (
          <button
            type="button"
            className="btn btn-primary bg-lightBlue text-navy-lighter border-0 rounded"
            onClick={this.addRule}
          >
            {`New ${title.slice(0, -1)}`}
          </button>
        )}
      </div>
    );
  }
}

RuleFieldsList.propTypes = {
  title: PropTypes.string.isRequired,
  initialRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      rule_definition_id: PropTypes.string,
      user_defined_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]),
      _destroy: PropTypes.bool
    })
  ).isRequired,
  ruleDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      query_table: PropTypes.string.isRequired,
      field_lock: PropTypes.string.isRequired,
      allowed_input_type: PropTypes.oneOf([
        'string',
        'integer',
        'boolean',
        'datetime',
        'trueonly',
        'falseonly'
      ]).isRequired
    })
  ).isRequired,
  startingIndex: PropTypes.number,
  disableEdit: PropTypes.bool.isRequired
};

RuleFieldsList.defaultProps = {
  startingIndex: 0
};

export default RuleFieldsList;

import HandleNumericOnInputWithForcedMinMax from './src/utils/HandleNumericInputWithEnhancedMinMax';
import $ from 'jquery';

$(() => {
  /*
    Example usage:
    <input type="numeric" min="0" max="100" data-numeric-force-min-max="true" />
    Using form helpers:
    <%= f.number_field :quantity, min: 0, max: 100, data: { numeric_force_min_max: true } %>
    <%= f.input :quantity, as: :integer, min: 0, max: 100, input_html: { data: { numeric_force_min_max: true } } %>
  */

  $(document).on(
    'input',
    'input[type="number"][data-numeric-force-min-max="true"]',
    HandleNumericOnInputWithForcedMinMax
  );
});

const brand = {
  navy: '#003a5d',
  navyLight: '#014e7d',
  navyDark: '#061938',
  orange: '#ff8300',
  blue: '#0072ed'
};
const blue = {
  100: '#f0f7ff',
  150: '#e1ecf9',
  200: '#add5ff',
  300: '#70b5ff',
  400: '#2e93ff',
  500: brand.blue,
  DEFAULT: brand.blue,
  600: '#005bbd',
  700: '#00458f',
  800: '#002f61',
  900: '#061938'
};
const green = {
  100: '#f8fcf8',
  200: '#dbf0db',
  300: '#b8e0b8',
  400: '#91cf91',
  500: '#5cb85c',
  DEFAULT: '#5cb85c',
  600: '#49a749',
  700: '#439943',
  800: '#3b855d',
  900: '#2b632b'
};
const yellow = {
  100: '#fffcf5',
  200: '#ffecc7',
  300: '#ffdb94',
  400: '#ffc400',
  500: '#ffab00',
  DEFAULT: '#ffab00',
  600: '#e69900',
  700: '#cc8800',
  800: '#a87000',
  900: '#7a5200'
};
const red = {
  50: '#f9dad2',
  100: '#f7c3b6',
  200: '#f4937b',
  300: '#f5623d',
  400: '#de350b',
  500: '#b92500',
  DEFAULT: '#b92500',
  600: '#952104',
  700: '#6f1b06',
  800: '#501507',
  900: '#2e0d05'
};
const orange = {
  100: '#ffebd7',
  DEFAULT: brand.orange
};

const grayscale = {
  25: '#e8e4e940',
  50: '#faf9fa',
  100: '#f2f0f3',
  200: '#e8e4e9',
  300: '#cecbcf',
  400: '#a3a1a3',
  500: '#737173',
  600: '#524d52',
  700: '#444043',
  800: '#2a282a',
  900: '#171117'
};

const baseColors = {
  text: grayscale['800'],
  bg: '#fff'
};

module.exports = {
  content: [
    'app/javascript/**/*.{html,js,mdx}',
    'app/views/**/*.{html,erb}',
    'packages/**/app/views/**/*.{html,erb}'
  ],
  blocklist: ['container', 'collapse'],
  theme: {
    extend: {
      colors: {
        current: 'currentColor',
        transparent: 'transparent',
        'brand-primary': brand.navy,
        'brand-secondary': brand.orange,
        'brand-blue': brand.blue,
        lightBlue: {
          DEFAULT: '#E6F1FD'
        },
        navy: {
          lighter: '#065d8c',
          light: brand.navyLight,
          DEFAULT: brand.navy,
          dark: brand.navyDark
        },
        orange: orange,
        blue: blue,
        green: green,
        yellow: yellow,
        red: red,
        link: {
          DEFAULT: '#0747a6',
          hover: '#04285d'
        },
        gray: grayscale
      },

      textColor: {
        color: baseColors.text
      },

      backgroundColor: {
        base: baseColors.bg
      },

      fontFamily: {
        sans: ['Nunito Sans', 'Helvetica', 'Arial', 'sans-serif'],
        serif: ['Georgia', 'Times New Roman', 'Times', 'serif'],
        monospace: ['Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace']
      },

      fontSize: {
        xs: '1.2rem',
        sm: '1.3rem',
        base: '1.5rem',
        lg: '1.8rem',
        xl: '2rem',
        '2xl': '2.4rem',
        '3xl': '3rem',
        '4xl': '3.6rem',
        '5xl': '4.8rem',
        '6xl': '6rem',
        '7xl': '7.2rem',
        '8xl': '9.6rem',
        '9xl': '12.8rem'
      },

      // automatically set padding around containers
      container: {
        center: true,
        padding: '15px'
      },
      maxWidth: {
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%'
      }
    },

    // match the bootstrap breakpoints that the site already uses
    // the *-max breakpoints should be 1px less than the regular versions
    screens: {
      xs: '480px',
      sm: '768px',
      md: '992px',
      lg: '1200px',
      xl: '1400px',
      xxl: '1536px',
      'xxl-max': { max: '1535px' },
      'xl-max': { max: '1399px' },
      'lg-max': { max: '1199px' },
      'md-max': { max: '991px' },
      'sm-max': { max: '767px' },
      'xs-max': { max: '479px' },
      // https://v2.tailwindcss.com/docs/breakpoints#styling-for-print
      print: { raw: 'print' }
    }
  },
  plugins: []
};

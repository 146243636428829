import React from 'react';
import PropTypes from 'prop-types';
import RulesFieldsList from '../src/components/RulesFields/RulesFieldsList';

class RulesFields extends React.Component {
  render() {
    const { initialRules, ruleDefinitions, disableEdit } = this.props;

    // Filter rule definitions based on the 'query_table' attribute
    const ordersRuleDefinitions = ruleDefinitions.filter(
      def => def.query_table === 'orders'
    );
    const organizationRuleDefinitions = ruleDefinitions.filter(
      def => def.query_table === 'organizations'
    );

    // Extract IDs for filtering rules
    const ordersRuleDefinitionIds = ordersRuleDefinitions.map(def => def.id);
    const organizationRuleDefinitionIds = organizationRuleDefinitions.map(
      def => def.id
    );

    // Filter initial rules based on rule definitions
    const ordersInitialRules = initialRules.filter(rule =>
      ordersRuleDefinitionIds.includes(rule.rule_definition_id)
    );
    const organizationInitialRules = initialRules.filter(rule =>
      organizationRuleDefinitionIds.includes(rule.rule_definition_id)
    );

    return (
      <>
        <RulesFieldsList
          title="Organization Rules"
          initialRules={organizationInitialRules}
          ruleDefinitions={organizationRuleDefinitions}
          disableEdit={disableEdit}
          startingIndex={0}
        />

        <RulesFieldsList
          title="Order Rules"
          initialRules={ordersInitialRules}
          ruleDefinitions={ordersRuleDefinitions}
          disableEdit={disableEdit}
          startingIndex={1000}
        />
      </>
    );
  }
}

RulesFields.propTypes = {
  initialRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      rule_definition_id: PropTypes.string,
      user_defined_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ]),
      _destroy: PropTypes.bool
    })
  ).isRequired,
  ruleDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      query_table: PropTypes.string.isRequired,
      field_lock: PropTypes.string.isRequired,
      allowed_input_type: PropTypes.oneOf([
        'string',
        'integer',
        'boolean',
        'datetime',
        'trueonly',
        'falseonly'
      ]).isRequired
    })
  ).isRequired,
  disableEdit: PropTypes.bool.isRequired
};

export default RulesFields;

/*
  The result of using this as an OnInput handler for type="number" inputs is that
  the input will be clamped to the min and max attributes of the input element.

  Default behavior for the min and max attributes of a number input is to allow
  the user to type in any value, and show a tooltip when the value is out of range.

  This script changes the behavior to clamp the value to the min and max range
  following the usability principle of "preventing errors" instead of "detecting errors".

  Currently used in /app/javascript/numeric_inputs_with_forced_min_max.js
  to enhance the behavior of number inputs that are using ERB form helpers.
*/

const handleNumericInput = event => {
  const { target } = event;
  const { value, min, max } = target;

  // Parse min and max as integers with sensible defaults
  const minInt = parseInt(min, 10) || 0;
  const maxInt = parseInt(max, 10) || 1000000000;

  if (value) {
    // Clamp the value within the min and max range
    const clampedValue = Math.min(
      Math.max(parseInt(value, 10), minInt),
      maxInt
    );
    target.value = clampedValue;
  } else {
    // Allow clearing the input
    target.value = '';
  }
};

export default handleNumericInput;

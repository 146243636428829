import { Analytics } from '@dispatch/analytics';
import posthogPlugin from '@dispatch/analytics-plugin-posthog-js';
import doNotTrack from 'analytics-plugin-do-not-track';

const posthogToken = window.posthogToken;

const plugins = [
  ...(posthogToken
    ? posthogPlugin({
        token: window.posthogToken,
        enabled: true,
        options: {}
      })
    : [])
];

if (plugins.length > 0) {
  plugins.push(doNotTrack());
}

const analytics = Analytics({
  app: 'Dispatch',
  plugins
});

analytics.ready(payload => {
  const posthogInstance = payload.instance.plugins.posthog;

  // We need to add a group called environment that passes in the environment name.
  // This allows us to turn on a feature flag for a specific environment (like a sub environment or stable environment).
  if (posthogInstance && window.environment) {
    const env = window.subenv_name
      ? `${window.environment}-${window.subenv_name}`
      : window.environment;

    posthogInstance.group('environment', env, {});
  }
});

window.posthog = analytics.plugins.posthog?.getPostHogInstance();

const isEnabled = plugins.length > 0;

const track = (event, properties) => {
  if (window.LogRocket) {
    window.LogRocket.track(event, properties);
  }

  analytics.track(event, properties);
};

const identify = userId => {
  analytics.identify({ userId });
};

const reset = () => {
  analytics.reset();
};

export { track, identify, reset, isEnabled };

export const MOCK_DATA = {
  ORGANIZATION: {
    name: 'Placeholder Organization',
    address: '4517 Washington Ave. Minneapolis, MN 39495',
    timezone: '(GMT-06:00) Central Time',
    vehicle_capacity_units: 'SKIDS',
    vehicle_capacity_unit: 'SKID',
    unit_description: 'The number of skids the order requires',
    hours_start: '09:00:00',
    hours_end: '17:00:00'
  },

  VEHICLES: [
    {
      id: '001',
      color: '#5243AA',
      name: 'Lorem ipsum',
      type: 'TRUCK',
      max_skids: 9,
      ownership: 'Dolor Sit'
    },
    {
      id: '002',
      color: '#009455',
      name: 'Consectetur Adipisicing',
      type: 'VAN',
      max_skids: 3,
      ownership: 'Ullamco Laboris'
    },
    {
      id: '003',
      color: '#E61151',
      name: 'Nisi Ut',
      type: 'CAR',
      max_skids: 2,
      ownership: 'Mollit Anim'
    }
  ],

  DRIVERS: [
    {
      id: '001',
      name: 'Anisa Ashton',
      phone: '(947) 647-7105'
    },
    {
      id: '002',
      name: 'Stefania Paul',
      phone: '(716) 388-2791'
    },
    {
      id: '003',
      name: 'Buddy Marsh',
      phone: '(650) 416-1536'
    },
    {
      id: '004',
      name: 'Sophie-Louise Knott',
      phone: '(934) 581-8642'
    }
  ]
};

export const ROUTE_COLORS = {
  'Dark Purple': '#5243AA',
  Cyan: '#1192E8',
  'Dark Teal': '#005D5D',
  Magenta: '#9F1853',
  Red: '#FA4D56',
  Green: '#009455',
  Blue: '#002D9C',
  Pink: '#EE5396',
  'Light Purple': '#A56EFF',
  'Light Teal': '#009D9A'
};

export const ROUTES = {
  ADDRESS_BOOK: () => `${ROUTES.ROOT()}/address_book`,
  ADDRESS_BOOK_ID: id => `${ROUTES.ADDRESS_BOOK()}/${id}`,
  SAVED_LOCATION: () => `${ROUTES.ADDRESS_BOOK()}/saved_locations`,
  SAVED_LOCATION_ID: id => `${ROUTES.ADDRESS_BOOK()}/saved_locations/${id}`,
  DELIVERIES: () => `${ROUTES.ROOT()}/deliveries`,
  DELIVERY: id => `${ROUTES.DELIVERIES()}/${id}`,
  PUBLIC_DELIVERY: guid => `${ROUTES.ROOT()}/delivery/${guid}`,
  IMPORT_DELIVERIES: () => `${ROUTES.ROOT()}/import/deliveries`,
  MARKETPLACE_ROOT: () => '/',
  REPORTING: () => `${ROUTES.ROOT()}/reporting`,
  ROOT: () => '/connect',
  SEARCH_DELIVERIES: () => `${ROUTES.ROOT()}/search/deliveries`,
  SETTINGS: () => `${ROUTES.ROOT()}/settings`,
  SETTINGS_VEHICLE: id => `${ROUTES.SETTINGS_VEHICLES()}/${id}`,
  SETTINGS_VEHICLES: () => `${ROUTES.SETTINGS()}/vehicles`,
  SETTINGS_USER: id => `${ROUTES.SETTINGS_USERS()}/${id}`,
  SETTINGS_USERS: () => `${ROUTES.SETTINGS()}/users`,
  STYLEGUIDE: () => `${ROUTES.ROOT()}/styleguide`,
  TERMS_AND_CONDITIONS: () => `${ROUTES.ROOT()}/terms_and_conditions`,
  NOT_FOUND: () => `${ROUTES.ROOT()}/not_found`,
  FORBIDDEN: () => `${ROUTES.ROOT()}/forbidden`
};

export const SUPPORT = {
  ROOT: () => 'https://support.dispatchit.com',
  MANAGING_A_CONNECT_SUBSCRIPTION: () =>
    `${SUPPORT.ROOT()}/managing-a-connect-subscription`
};

export const USER_ROLES = [
  {
    name: 'connect_web',
    label: 'Connect Web App User',
    description:
      'User is able to view all deliveries and pages (dashboard and settings). They are able to manage deliveries and Will Call orders, add vehicles and upload vehicle photos. They are not able to add or edit users.'
  },
  {
    name: 'connect_web_admin',
    label: 'Connect Web App Administrator',
    description:
      'User is able to view all deliveries and pages (dashboard and settings). They are able to manage deliveries and Will Call orders, add vehicles and upload vehicle photos. They are able to add and edit users.'
  },
  {
    name: 'connect_web_read_only',
    label: 'Connect Web App Read Only',
    description:
      'User is able to view all deliveries and pages (dashboard and settings). They are not able to interact with any features on the web app.'
  },
  {
    name: 'connect_driver',
    label: 'Connect Driver',
    description:
      'Driver will have access to use the Dispatch Connect Driver mobile app.'
  }
];

export const BILLING_CYCLES = ['monthly', 'annual'];

export const TIERS = {
  TRIAL: 'Trial',
  STARTER: 'Starter',
  PROFESSIONAL: 'Professional'
};

export const DELIVERY_STATUSES = {
  STATUSES: [
    'unassigned',
    'incomplete',
    'assigned',
    'scheduled',
    'pending_pickup',
    'out_for_delivery',
    'will_call',
    'sent_to_marketplace',
    'attempted',
    'complete',
    'cancelled'
  ],
  PICKUP_READ_ONLY_STATUSES: ['out_for_delivery', 'complete', 'cancelled'],
  DROP_OFF_READ_ONLY_STATUSES: ['complete', 'cancelled'],
  STATUS_FILTER_OPTIONS: [
    { value: 'unassigned', label: 'Unassigned' },
    { value: 'assigned', label: 'Assigned' },
    { value: 'pending_pickup', label: 'Pending Pickup' },
    { value: 'out_for_delivery', label: 'Out for Delivery' },
    { value: 'complete', label: 'Complete' },
    { value: 'will_call', label: 'Will Call' },
    { value: 'sent_to_marketplace', label: 'Sent to Dispatch' },
    { value: 'incomplete', label: 'Incomplete' },
    { value: 'attempted', label: 'Attempted' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'scheduled', label: 'Scheduled' }
  ]
};

export const DASHBOARD_VIEWS = {
  PLAN_VIEW: 'plan',
  MAP_VIEW: 'map',
  LIST_VIEW: 'list'
};

export const DELIVERY_FORMAT_OPTIONS = [
  {
    name: 'None',
    id: 'delivery-format-none',
    value: 'none'
  },
  {
    name: 'SMS and Email',
    id: 'delivery-format-both',
    value: 'both'
  },
  {
    name: 'SMS',
    id: 'delivery-format-sms',
    value: 'sms'
  },
  {
    name: 'Email',
    id: 'delivery-format-email',
    value: 'email'
  }
];

import React from 'react';
import { dateInputType } from '../../types/dateTimeDataType';
import DateTimeInput from '../DateTimeInput/DateTimeInput';

const DateInput = ({ dateFormat, placeholder, minDate, ...remainingProps }) => {
  let parsedMinDate = minDate;

  if (typeof minDate === 'string') {
    const parsedDate = Date.parse(minDate);
    if (!isNaN(parsedDate)) {
      parsedMinDate = new Date(parsedDate);
    } else {
      parsedMinDate = null;
    }
  }

  return (
    <DateTimeInput
      dateFormat={dateFormat || 'M/d/yyyy'}
      placeholder={placeholder || 'mm/dd/yyyy'}
      minDate={parsedMinDate}
      {...remainingProps}
    />
  );
};

DateInput.defaultProps = {
  disabled: false,
  showRequiredIndicator: true
};

DateInput.propTypes = {
  ...dateInputType
};

export default DateInput;
